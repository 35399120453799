var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ml-2" },
    [
      _c("h4", { staticClass: "mb-2" }, [_vm._v("Reference Flags")]),
      _vm._l(_vm.flags, function(item, index) {
        return [
          _c(
            "div",
            { key: index, staticClass: "d-flex" },
            [
              _c("v-icon", { staticClass: "primary--text mr-3" }, [
                _vm._v(_vm._s(item.icon))
              ]),
              _c(
                "v-chip",
                {
                  staticClass:
                    "align-center d-flex text-uppercase font-weight-bold",
                  staticStyle: { width: "280px" },
                  attrs: { color: item.color }
                },
                [_vm._v(_vm._s(item.text))]
              )
            ],
            1
          ),
          index < _vm.flags.length - 1
            ? _c("v-divider", {
                key: "divider-" + index,
                staticClass: "my-2 grey lighten-2"
              })
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }