<template>
    <div class="ml-2">
        <h4 class="mb-2">Reference Flags</h4>
        <template v-for="(item, index) in flags">
            <div :key="index" class="d-flex">
                <v-icon class="primary--text mr-3">{{ item.icon }}</v-icon>
                <v-chip
                    style="width: 280px"
                    class="align-center d-flex text-uppercase font-weight-bold"
                    :color="item.color"
                    >{{ item.text }}</v-chip
                >
            </div>
            <v-divider
                v-if="index < flags.length - 1"
                :key="`divider-${index}`"
                class="my-2 grey lighten-2"
            ></v-divider>
        </template>
    </div>
</template>
<script>
export default {
    name: "rental-history-reference",
    components: {},
    props: {
        reference: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        flags() {
            return this.reference.flags.map(({ flag, severity }) => {
                return {
                    icon: "mdi-flag",
                    type: "chip",
                    text: flag,
                    label: "",
                    class: "chip--flag align-center d-flex ",
                    color:
                        severity === "critical"
                            ? "error darken-1"
                            : "warning darken-2",
                };
            });
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>